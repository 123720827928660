import React from "react";
import MainContent from "../../containers/Student/MainContent";
import cssClasses from "./layout.module.css"
import SideNavEtudiant from "../../containers/Student/sideNavEtudiant";

function LayoutEtudiant(props) {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <>
            <div className={cssClasses.layout}>
                <SideNavEtudiant mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
                <MainContent mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/>
            </div>
        </>
    );

}

export default LayoutEtudiant;

