import React from "react";
import SideNavItem from "../SideNavItem";

function SideNavItems(props) {

    const sideNavItems = props.routes.map(route => {
        return !route.notInSideNav && <SideNavItem role={props.role} onClick={props.onClick} isAuthenticated={props.isAuthenticated} withLabel={props.withLabel} route={route} />
    });

    return (
        <div className={"mt-3"}>
            {
                sideNavItems
            }
        </div>
    );


}

export default SideNavItems;