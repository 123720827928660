import React from "react";
import Card from "@material-ui/core/Card";
import {Link} from "react-router-dom";
import {OffersPage} from "../../routes/studentRoutes";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

function CoursCategorieTopCard(props) {

    const item = props.item;

    const backArrow = props.backArrow;

    const enrolledAlert = () => {

        if (props.isEnrolled) {
            return null;
        } else {
            return (
                <>
                    <Alert variant={"outlined"} severity="warning">
                        Vous n'êtes pas inscrit à ce cours!
                    </Alert>
                    <div className={"text-center mb-3"}>
                        <Link to={OffersPage.path}>
                            <Button size="small" className={"mt-3"} variant={"contained"} color="secondary">
                                Consulter nos offres
                            </Button>
                        </Link>
                    </div>
                </>

            );

        }
    };


    return (

        <>
            <div>
                {enrolledAlert()}
            </div>

            <Card className={"p-1 position-relative"}>
                {
                    backArrow &&
                    <Link to={backArrow} className={"position-absolute"}>
                        <IconButton className={"my-3"}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Link>
                }
                <div className={"d-flex align-items-center justify-content-center"}>
                    <h1 className={"text-center my-3"}>
                        {item.title}
                    </h1>
                </div>
            </Card>

        </>
    );


}

export default CoursCategorieTopCard;
