import React from "react";
import {NavLink} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import cssClasses from "./sidenavitem.module.css";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";

function SideNavItem({route, withLabel,isAuthenticated,onClick,role}) {

    const iconClasses = [route.icon, cssClasses.icon,"d-flex","align-items-center","justify-content-center"];
    const labelClasses = ["ml-3"];
    const navLinkClasses = ["d-flex","align-items-center","pl-2","py-2"];
    const dividerCLasses = ["text-center","bg-light","py-2","border"];
    if(!withLabel) {
        labelClasses.push("d-none")
        navLinkClasses.push("justify-content-center");
    }

    let render = !route.notInSideNav && ((!route.requireAuthentication && !route.requireUnAuthentication) || (route.requireAuthentication && isAuthenticated) || (route.requireUnAuthentication && isAuthenticated));

    if(render) {
        if(route.requiredRole && isAuthenticated) {
            if(role !==route.requiredRole)
                render = false;
        }
    }


    return (
            route.type === "route" ?
<>
    <NavLink to={route.path}>
            <ListItem button>
                <ListItemIcon><Icon className={route.icon} /></ListItemIcon>
                <ListItemText primary={route.label} />
            </ListItem>
    </NavLink>


{/*
            <NavLink onClick={onClick} to={route.path} activeClassName={cssClasses.active} className={navLinkClasses.join(" ")}>
                <Icon className={iconClasses.join(" ")} />
                <span className={labelClasses.join(" ")}>{route.label}</span>
            </NavLink>*/
}
    </>
            :
            <div className={dividerCLasses.join(" ")}>{route.label}</div>

        );
}

export default SideNavItem;