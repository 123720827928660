import React from "react";
import SideNavItems from "../../../components/SideNavItems";


import List from '@material-ui/core/List';
import Divider from "@material-ui/core/Divider";

import miniLogo from "../../../assets/miniLogo.png";

import Drawer from '@material-ui/core/Drawer';

import routes from "../../../routes/studentRoutes";
import {CssBaseline, Hidden} from "@material-ui/core";

function SideNavEtudiant(props) {

    const {mobileOpen, handleDrawerToggle} = props;

    const drawer = (
        <>
            <div className={"text-center p-2"} style={{height: 64}}>
                <img height={50} src={miniLogo}/>
            </div>
            <Divider/>
            <List style={{width: 175}}>
                <SideNavItems routes={routes}/>
            </List>
        </>
    )

    return (
        <>
            <CssBaseline/>
            <Hidden xsDown implementation="css">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 175},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </>

    );

}


export default SideNavEtudiant;